//import recurso1 from '../resources/recurso1_white.png'; // Tell webpack this JS file uses this image
import  {ReactComponent as Recurso1} from '../resources/recurso1_white_vect.svg'; // Tell webpack this JS file uses this image

function Home() {
const wa_link = 'https://wa.me/595985126663?text=Hola!'

  return (
    <div> 
      {/*   Logo portada  */}    
      <div className="p-5 mb-12 rounded-3">
        {/* <div className="container-fluid py-5 text-center align-items-center bloque"> */}
        <div className="container-fluid d-flex align-items-center justify-content-center bloque fade-in">
          {/* <img class="img-fluid col-md-4 text-center" src={recurso1} alt="recurso1" /> */}
          <Recurso1/>
        </div>
       </div>
       {/*   Botonera  */}
       <div className="container">
         <div className="row justify-content-center fade-in">
            <div className="col-12 col-sm-6">
              <div className="btn-group d-flex">
                <a className="btn btn-lg btn-icon text-white color-transition" type="button" href={wa_link} target="_blank" rel="noreferrer">
                  <span className="bi bi-whatsapp" ></span>
                </a>
                <a className="btn btn-lg btn-icon text-white" type="button" href="https://www.facebook.com/people/NovaSur/100088455564561/" target="_blank" rel="noreferrer">
                  <span className="bi bi-facebook"></span>
                </a>
                <a className="btn btn-lg btn-icon text-white" type="button" href="https://www.instagram.com/novasur.py/" target="_blank" rel="noreferrer">
                  <span className="bi bi-instagram"></span>
                </a>
                <a className="btn btn-lg btn-icon text-white" type="button" href="https://www.twitch.tv/novasurpy" target="_blank" rel="noreferrer">
                  <span className="bi bi-twitch"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
    </div>
    
  );
}

export default Home;
