import evento001 from '../../resources/about1.jpg';
import evento002 from '../../resources/flyer_evento.png';

function Evento1() {

  const evento_insc_url = 'https://docs.google.com/forms/d/e/1FAIpQLScyJ5qvz8lYU2x-o85RgepEWYAoCnRTcabqp3fhCgt1YDj9vQ/viewform';
  const evento_ubi_url = 'https://goo.gl/maps/Z7rcraw5s4AgKmjD8?coh=178572&entry=tt';
  const evento_whatsapp_url = 'https://wa.me/595985126663?text=Hola!';

    return (    
       <div className="container-fluid text-white fade-in animate-sequence">
        <div className="row">
          <div className="col-md-6">
            <div className="card bg-dark text-white mb-4 transparencia">
              <div className="card-body">
                <h5 className="card-title">GRAN EVENTO GAMING FEST ENCARNACIÓN - FIFA 2023</h5>
               <p className="card-text">
               <br/>
                300 USD EN PREMIOS<br/>
                Fechas el 3, 4 y 10 de junio 2023  📅<br/>
                <br/>
                📍 Lugar del evento: Salón de Eventos Casino Carnaval<br/>
                <br/>
                 Consideraciones para la competencia:<br/>
                 <br/>
                  1.	64 jugadores (formato bracket)<br/>
                  2.	Eliminación directa (partido unico)<br/>
                  3.	Duración 6 min<br/>
                  4.	Clubes de la Uefa Champios League Temporada 2022/23<br/>
                  5.	Consola: PlayStation 4<br/>
                  6.	Participantes deben traer sus joysticks<br/>
                <br/>
                Costo de Inscripción: 100.000Gs<br/>
                ⏩ Pueden hacer sus pagos en efectivo en:<br/>
                Galway Irish Pub<br/>
                Vape House - Sucursales Centro o Circuito<br/>
                O a través de transferencias o giros, solicitando los datos al número de consultas más abajo 📲<br/>
                </p>
                {/* Aca van los links en forma de botones */}
                <a className="btn btn-icon text-white color-transition" type="button" href={evento_ubi_url} target="_blank" rel="noreferrer">
                  <span className="bi bi-geo-alt" ></span> Lugar 
                </a>
                <a className="btn btn-icon text-white color-transition" type="button" href={evento_insc_url} target="_blank" rel="noreferrer">
                  <span className="bi bi-pencil" ></span> Inscripción 
                </a>
                <a className="btn btn-icon text-white color-transition" type="button" href={evento_whatsapp_url} target="_blank" rel="noreferrer">
                  <span className="bi bi-whatsapp" ></span> Consultas 
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <img className="img-fluid text-center" src={evento002} alt="Evento FIFA 23 GALWAY" style={{maxHeight: '90%'}} />
          </div>
        </div>
       </div>
    );
  }
  
  export default Evento1;
  