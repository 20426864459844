import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link } from 'react-router-dom';


function Navbar2(props) {

  let current_theme = 'light'
  const colorModes = [{name: 'light', className: 'bi bi-sun-fill'}, {name: 'dark', className: 'bi bi-moon-fill'}]
  const storedTheme = localStorage.getItem('theme')

  const getPreferredTheme = () => {
    if (storedTheme) {
      return storedTheme
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  }

  const setTheme = function (theme) {
    if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.setAttribute('data-bs-theme', 'dark')
    } else {
      document.documentElement.setAttribute('data-bs-theme', theme)
    }
    current_theme = theme
    localStorage.setItem('theme', theme)
  }

  setTheme(getPreferredTheme())
  /*
  const changeTheme = function(){
    current_theme = (current_theme === 'light' ? 'dark' : 'light')
    setTheme(current_theme)
    changeIcon(current_theme)
  }
 

  const changeIcon = function(theme){
    let colorMode = colorModes.find(({ name }) => name === theme)
    document.getElementById("color-mode").className = colorMode.className;
  }
 */
  let menu = [
    { label: 'Inicio', name: 'home', url: '/', is_external: false },
    { label: 'Acerca de', name: 'about', url: 'about', is_external: false },
    /*{ label: 'Eventos', name: 'event', url: '/events', is_external: false },*/
    { label: 'Twich', name: 'home', url: 'https://www.twitch.tv/novasurpy', is_external: true }/*,
    { label: 'Contactos', name: 'about', url: '/about', is_external: false }*/
  ]
  const items = menu.map((item, i) => (
    <li className="nav-item" key={i}>
      <Link to={item.url}   
        className="nav-link text-light" aria-current="page"
        target={item.is_external ? "_blank" : undefined}  >
        {item.label}
      </Link>
    </li>
  ));
  
  
  

  return (
    <nav className="navbar navbar-expand-sm">
      <div className="container-fluid">
        <a className="navbar-brand"></a>
        <button className="navbar-toggler navbar-color" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="bi bi-list text-white"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {items}
          </ul>
          {/* <button className="d-flex nav-link btn btn-link" aria-label="color-mode" onClick={changeTheme}>
            <i id='color-mode' className="bi bi-sun-fill" aria-hidden="true" style={{fontSize: "32px"}}></i>
          </button> */}
        </div>
      </div>
    </nav>
  );

}

export default Navbar2;
