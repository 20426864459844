import eventos1 from '../resources/eventos01.png';
import { Link } from 'react-router-dom';

function Events() {
   const evento1_insc_url = 'https://docs.google.com/forms/d/e/1FAIpQLScyJ5qvz8lYU2x-o85RgepEWYAoCnRTcabqp3fhCgt1YDj9vQ/viewform';
   const evento1_info_url = 'https://docs.google.com/forms/d/e/1FAIpQLScyJ5qvz8lYU2x-o85RgepEWYAoCnRTcabqp3fhCgt1YDj9vQ/viewform';

    return (
      <div className="container-fluid text-white fade-in animate-sequence">
        <div className="row">
          <div className="col-md-6 mx-auto">
          <div className="row justify-content-center">
          <div className="col-md-6">
            <h1 className="text-white text-center mt-5">Próximos Eventos</h1>
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="card bg-dark text-white mb-4">
                  <div className="card-body">
                    <h5 className="card-title">Torneo de FIFA 23</h5>                    
                    <a/>
                    <p className="card-text">Fecha: 03/06/2023</p>
                    <Link to="/evento1" >
                      <button className="btn btn-icon text-white color-transition" type="button" >Info aquí <span className="bi bi-arrows-move" ></span></button>
                    </Link>
                    <a className="btn btn-icon text-white color-transition" type="button" href={evento1_insc_url} target="_blank" rel="noreferrer">
                       Inscripción <span className="bi bi-pencil" ></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card bg-dark text-white mb-4 color-transition">
                  <div className="card-body">
                    <h5 className="card-title ">Torneo de FIFA 23</h5>
                    <p className="card-text">Fecha: 04/06/2023</p>
                    <Link to="/evento1" >
                      <button className="btn btn-icon text-white color-transition" type="button" >Info aquí <span className="bi bi-arrows-move" ></span></button>
                    </Link>
                    <a className="btn btn-icon text-white color-transition" type="button" href={evento1_insc_url} target="_blank" rel="noreferrer">
                       Inscripción <span className="bi bi-pencil" ></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card bg-dark text-white mb-4">
                  <div className="card-body">
                    <h5 className="card-title">Torneo de FIFA 23</h5>
                    <p className="card-text">Fecha: 10/06/2023</p>
                    <Link to="/evento1" >
                      <button className="btn btn-icon text-white color-transition" type="button" >Info aquí <span className="bi bi-arrows-move" ></span></button>
                    </Link>
                    <a className="btn btn-icon text-white color-transition" type="button" href={evento1_insc_url} target="_blank" rel="noreferrer">
                       Inscripción <span className="bi bi-pencil" ></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            
            {/*
              <div class="row">
              <div class="col-md-6">
                <div class="card bg-dark text-white mb-4">
                  <div class="card-body">
                    <h5 class="card-title">Fecha 3</h5>
                    <p class="card-text">Descripción del evento 3</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card bg-dark text-white mb-4">
                  <div class="card-body">
                    <h5 class="card-title">Fecha 4</h5>
                    <p class="card-text">Descripción del evento 4</p>
                  </div>
                </div>
              </div>
            </div>
            */}

          </div>
        </div>
          </div>
          <div className="col-md-6">
            <img className="img-fluid text-center" src={eventos1} alt="about1" />
          </div>
        </div>
      </div>
    );
  }
  
  export default Events;
  