import './App.css';

import Layout from './pages/layout';
import Navbar2 from './pages/navbar2';
import Home from './pages/home'
import About from './pages/about'
import Events from './pages/events'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
/*paginass que necesitamo para utilizar con el Router*/
import Evento1 from './pages/events/evento1'

function App() {
  return (
    <>
    {/*<Layout />*/}
    <Router>
      {/*<Navbar /> Lo correcto es colocar aqui el navbar*/}
      <Navbar2 />
      <Routes>
        {/*<Route path="/" element={<Layout />} /> ACA SE ARMA EL QUILOMBO, PERO ASI ESTABA ANTES*/}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/events" element={<Events />} />
        <Route path="/evento1" element={<Evento1 />} />
      </Routes>
    </Router>
  </>
  );
}

export default App;