import about1 from '../resources/about1.jpg';

function About() {
    return (
      <div className="container-fluid text-white fade-in animate-sequence">
        <div className="row">
          <div className="col-md-6 mx-auto">
          <h1 className="text-center mt-5">Conozca nuestra empresa</h1>
          <p className="text-center col-md-6 mx-auto py-5">
              Somos una empresa encarnacena con alto compromiso con el deporte 
              electrónico buscando crear cultura sobre los mismos. Estamos
              enfocados en cambiar la percepción de las personas en cuanto al
              mundo de los Esports y como desarrollarlos para crear un ecosistema
              de enseñanza y despertar el espíritu competitivo de los participantes.
          </p>
          </div>
          <div className="col-md-6">
            <img className="img-fluid text-center" src={about1} alt="about1" />
          </div>
        </div>
        <div className='row bg-custom'>
            <div className="col-md-4">
              <h3 className="text-center pt-4">Misión</h3>
              <p className="text-center col-md-6 mx-auto py-4">
                Crear cultura deportiva de
                aprendizaje y generar competicia
                sana dentro de los Esports.
              </p>
            </div>
            <div className="col-md-4">
              <h3 className="text-center pt-4">Visión</h3>
              <p className="text-center col-md-6 mx-auto py-4">
                Ser la principal productora de
                eventos de la Region e innovar
                con la tecnologia hacia el futuro
              </p>
            </div>
            <div className="col-md-4">
              <h3 className="text-center pt-4">Valores</h3>
              <p className="text-center col-md-6 mx-auto py-4">
                  Disciplina<br/>
                  Aprendizaje<br/>
                  Mejorar nivel competitivo<br/>
              </p>
            </div>
          </div>
      </div>
    );
  }
  
  export default About;
  